(function ($) {

    var msg = "";

    var methods = {
        init: function (options) {

            // This is the easiest way to have default options.
            var settings = $.extend({
                // These are the defaults.
                showSearchSummary: false,
                searchSummary: {},
                showBannerSlider: true,
                text: '',
                title: ''
            }, options);

            this.find('.original-content').siblings().remove();

            msg = this.find('.original-content').html();

            if (settings.showSearchSummary) {

                msg = msg.replace("#destination#", settings.searchSummary.destination);
                msg = msg.replace("#from#", settings.searchSummary.from);
                msg = msg.replace("#to#", settings.searchSummary.to);

                if (settings.searchSummary.childrenCount == 0) {
                    if (settings.searchSummary.roomsCount == 1) {
                        msg = msg.replace("#passengers#", LOCALIZATIONS.RoomTextAdults.replace("%adultos%", settings.searchSummary.adultsCount).replace("%habs%", settings.searchSummary.roomsCount));
                    } else {
                        msg = msg.replace("#passengers#", LOCALIZATIONS.RoomTextAdultsPlural.replace("%adultos%", settings.searchSummary.adultsCount).replace("%habs%", settings.searchSummary.roomsCount));
                    }

                } else {
                    if (settings.searchSummary.roomsCount == 1) {
                        msg = msg.replace("#passengers#", LOCALIZATIONS.RoomTextAdultsChilds.replace("%adultos%", settings.searchSummary.adultsCount).replace("%habs%", settings.searchSummary.roomsCount).replace("%ninos%", settings.searchSummary.childrenCount));
                    } else {
                        msg = msg.replace("#passengers#", LOCALIZATIONS.RoomTextAdultsChildsPlural.replace("%adultos%", settings.searchSummary.adultsCount).replace("%habs%", settings.searchSummary.roomsCount).replace("%ninos%", settings.searchSummary.childrenCount));
                    }
                }
            } else {
                
                msg = $('<div/>').html($(msg).find('.searchingmodal').hide().end()).html();
                
            }

            if (!settings.showBannerSlider) {
                msg = $('<div/>').html($(msg).find('.gallery').hide().end()).html();
                
            }

            if (settings.text != "") {
                if ($(msg).find('.modal-title').length > 0) {
                    msg = $('<div/>').html($(msg).find('.modal-title').text(settings.text).end()).html();
                } else {
                    msg = $('<div/>').html($(msg).find('.splashdetails').text(settings.text).end()).html();
                }
                
            }
            if (settings.title != "") {
                
                msg = $('<div/>').html($(msg).find('.splashtitle').text(settings.title).end()).html();
                

            }

            

            return this;

        },
        show: function () { this.append(msg).show(); },
        hide: function () { this.hide(); },
        get: function() { return msg; }
    };

    $.fn.splash = function (methodOrOptions) {

        if (methods[methodOrOptions]) {
            return methods[methodOrOptions].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof methodOrOptions === 'object' || !methodOrOptions) {
            // Default to "init"
            return methods.init.apply(this, arguments);
        } else {
            $.error('Method ' + methodOrOptions + ' does not exist on jQuery.splash');
        }

        

    };

}(jQuery));


tpj(document).ajaxError(function (event, XMLHttpRequest, ajaxOptions, thrownError) {
    /*if (XMLHttpRequest.status == 403) {
        window.location.href = Environment_APP_PATH;
    }
    else {
        SystemErrorMessage.show(XMLHttpRequest.status);    
    }*/
    
});

SystemErrorMessage = new function () {
    var _self = this;

    /*_self.show = function (code) {
        if(tpj("#internal-server-error").html() != "undefined")
            MessageBox.error("Error " + code + "!", $("#internal-server-error").html());
        else
            MessageBox.error("Error " + code + "!", "Error procesando solicitud");
    };*/
};

jQuery.fn.extend({
    heavyLoad: function (url, data, success) {
        if (jQuery(this).find('#loading-indicator').length <= 0) {
            var loadingIndicator = jQuery("<div class='loadPag'><img src='" + ASSETS_DOMAIN + "/Content/images/loader.gif' /><br/>Cargando...</div>");

            jQuery(this).prepend(loadingIndicator);
        }
        
        jQuery(this).load(Environment_APP_PATH + url, data, function () {
            if(typeof loadingIndicator != "undefined")
                loadingIndicator.remove();
            if (success) success();
        });
    }
});

jQuery.unparam = function (url) {
    url = decodeURIComponent(url.replace(/\+/g, ' '));

    var regex = /([^=&?]+)=([^&#]*)/g, params = {}, parts, key, value;

    while ((parts = regex.exec(url)) != null) {

        key = parts[1], value = parts[2];

        if (params[key] != undefined) {
            if (tpj.isArray(params[key])) {
                params[key].push(value);
            }
            else {
                var firstValue = params[key];

                params[key] = [];
                params[key].push(firstValue);
                params[key].push(value);
            }
        }
        else {
            params[key] = value;
        }
    }

    return params;
};

MessageBox = new function () {
    var _self = this;

    _self.info = function (title, message, onClose) {
        _show(title, message, null, "info", onClose);
    };

    _self.error = function (title, message) {
        _show(title, message, null, "alert");
    };

    _self.error1 = function (title, message, buttons, onClose) {
        _show(title, message, buttons, "alert", onClose);
    };

    _self.alert = function (title, message, buttons) {
        _show(title, message, buttons, "alert");
    };

    _self.warning = function (title, message, buttons) {
        if (buttons != null) {
            buttons.No = function () { tpj(this).dialog("close"); };
        }
        _dialog(title, message, buttons, "alert");
    };

    var _dialog = function (title, message, buttons, icon) {
        tpj("<div>")
            .addClass("message-box")
            .html("<span class='ui-icon ui-icon-" + icon
                + "'></span><span class='ui-message'>" + message + "</span>")
            .appendTo("body")
            .dialog({
                title: title,
                resizable: false,
                modal: true,
                width:360,    
                buttons: buttons,
                close: function () { tpj(this).dialog("destroy").remove(); }
            });
    };

    var _show = function (title, message, buttons, icon, onClose) {
        if (buttons != null) {
            buttons.Cancelar = function () { tpj(this).dialog("close"); };
        } else {
            buttons = { "Cerrar": function () { tpj(this).dialog("close"); if (onClose) onClose(); } };
        }
        _dialog(title, message, buttons, icon);
    };
};

function CheckCookie() {
    var location = window.location.pathname;
    if (location.split('/')[1] == 'cookie-error') {
        return;
    }
    
    tpj.removeCookie("MICROSITE_CONTEXT_CLIENT_CODE");

    tpj.cookie("MICROSITE_CONTEXT_CLIENT_CODE", Client_Code, { expires: 30, path: '/' });

    var cookie = tpj.cookie('MICROSITE_CONTEXT_CLIENT_CODE');
    if (Client_Code != cookie) {
        var ie = tpj.browser.msie == true;
        window.location.href = Environment_APP_PATH + 'cookie-error/' + (ie ? 'ie' : 'other');
    }
}

function reloadTopBar(reloadUrl) {

    if (typeof reloadUrl == "undefined")
        reloadUrl = "";


    tpj.ajax({

        url: Environment_APP_PATH + "Public/GetTopBar",
        type: 'get',
        data: {reloadUrl : reloadUrl},
        success: function (data) {
            tpj('.botonera').replaceWith(data);
        }

    });
}
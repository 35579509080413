tpj.extend({
    newsletter: new function () {
        var self = this;
        var form;
        var emailPattern = new RegExp("[a-z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?");

        self.initialize = function () {
            form = tpj('#newsletter-form');

            attachBehaviour();
        };

        var attachBehaviour = function () {
            tpj("#newsletter-form #send").click(function () {
                if (tpj("#newsletter-form #email-newsletter").val() != "" && emailPattern.test(tpj("#newsletter-form #email-newsletter").val())) {
                    confirm();
                } else {
                    MessageBox.error("Email inválido", "Introduzca un email válido");
                }
            });


            

        };

        var confirm = function () {

            tpj('#overlay2').splash({
                title: LOCALIZATIONS.SendingRequest + '...'
            });
            tpj('#overlay2').splash('show');

            

            tpj.ajax({
                type: "POST",
                url: Environment_APP_PATH + "core/register-me",
                cache: false,
                data: { Email: tpj("#newsletter-form #email-newsletter").val() },
                dataType: "json",
                success: function (data) {
                    tpj('#overlay2').splash('hide');

                    if (data.Success) {
                        MessageBox.info("Solicitud enviada", "Su solicitud ha sido enviada.");
                    }
                    else {
                        MessageBox.error("Error", data.ErrorMessages);
                    }

                    tpj("#newsletter-form #email-newsletter").val("");
                    tpj(".validation-summary-errors", form).removeClass("validation-summary-errors").addClass("validation-summary-valid");
                },
                error: function () {
                    tpj('#overlay2').splash('hide');
                }
            });
        };
    }
});

tpj(function () {
    tpj.newsletter.initialize();
});

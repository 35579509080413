tpj(function () {
    var register_request_pending = false;
    var reset_request_pending = false;
    var headerSearchFormTimerHandler;

    tpj('form.validate-recaptcha').one('submit', function (ev) {
        var form = tpj(this);
        ev.preventDefault();

        if (grecaptcha) {
            grecaptcha.execute(DEFAULT_RECAPTCHA_KEY, { action: 'Contact' }).then(function (token) {
                form.find('input[name="recaptchaToken"]').remove();
                form.append('<input type="hidden" name="recaptchaToken" value="' + token + '" />')
                form.submit();
            });
        } else {
            form.submit();
        }        
    })

    tpj('#login-global #register-block #register-ajax').click(function () {


        if (!register_request_pending) {
            register_request_pending = true;
            tpj.ajax({
                type: "POST",
                url: Environment_APP_PATH + "Account/RegisterFinalClientAjax",
                cache: false,
                data: tpj('#register-block #customer-info-form').serialize(),
                dataType: "json",
                success: function (data) {

                    if (data.success) {
                        alert("Registro correcto");
                        window.location.reload();
                    } else {
                        alert("Registro incorrecto");
                    }
                    register_request_pending = false;
                },
                error: function () {
                    alert("Registro incorrecto");
                    register_request_pending = false;
                }
            });
        }

    });

    tpj('#logReg #reset-password').click(function () {
        if (!reset_request_pending) {
            var email = tpj('#logReg #reset-block #email').val();
            reset_request_pending = true;
            tpj.ajax({
                type: "POST",
                url: Environment_APP_PATH + "Account/ResetPassword",
                cache: false,
                data: { email: email },
                dataType: "json",
                success: function (data) {

                    if (data.success) {
                        alert(LOCALIZATIONS.ResetPasswordSuccess);
                        tpj("#logReg #accordion").accordion("option", "active", 0);
                        //tpj("#accordion").accordion("option", "active", 0);
                    } else {
                        //MessageBox.alert("Error", "Email no encontrado");
                        alert(LOCALIZATIONS.EmailNotFound);
                    }
                    reset_request_pending = false;
                },
                error: function () {
                    reset_request_pending = false;
                }
            });
        }

    });


    tpj('#agency-login #reset-password-agency').click(function () {
        if (!reset_request_pending) {
            var user = tpj('#agency-login #user-agency-reset').val();
            reset_request_pending = true;
            tpj.ajax({
                type: "POST",
                url: Environment_APP_PATH + "Account/ResetPasswordAgency",
                cache: false,
                data: { user: user },
                dataType: "json",
                success: function (data) {
                    if (data.success) {
                        alert(LOCALIZATIONS.ResetPasswordSuccess);
                        tpj("#agency-login #accordion-agency").accordion("option", "active", 0);
                        //tpj("#accordion").accordion("option", "active", 0);
                    } else {
                        //MessageBox.alert("Error", "Email no encontrado");
                        alert(LOCALIZATIONS.LoginIncorrecto);
                    }
                    reset_request_pending = false;
                },
                error: function () {
                    reset_request_pending = false;
                }
            });
        }

    });

    tpj('#affiliate-login #reset-password-affiliate').click(function () {
        if (!reset_request_pending) {
            var user = tpj('#affiliate-login #user-affiliate-reset').val();
            reset_request_pending = true;
            tpj.ajax({
                type: "POST",
                url: Environment_APP_PATH + "Account/ResetPasswordAffiliate",
                cache: false,
                data: { user: user },
                dataType: "json",
                success: function (data) {
                    if (data.success) {
                        alert(LOCALIZATIONS.ResetPasswordSuccess);
                        tpj("#affiliate-login #accordion-affiliate").accordion("option", "active", 0);
                        //tpj("#accordion").accordion("option", "active", 0);
                    } else {
                        //MessageBox.alert("Error", "Email no encontrado");
                        alert(LOCALIZATIONS.LoginIncorrecto);
                    }
                    reset_request_pending = false;
                },
                error: function () {
                    reset_request_pending = false;
                }
            });
        }

    });

    tpj(".toggle-form").click(function () {
        tpj(tpj(this).data('target')).css('left', tpj(this).offset().left - tpj(tpj(this).data('target')).width()).slideToggle();
    });

    tpj('#faq-modal p').hide();

    tpj('#faq-modal ul li').append('<i class="fa fa-chevron-down pull-right"></i>').prepend('<i class="fa fa-info-circle"></i> ');

    tpj('#faq-modal ul').click(function () {
        tpj('#faq-modal p').slideUp();
        tpj(this).nextUntil('ul', 'p').slideDown();
    })

    tpj("#agency-login-btn").click(function () {
        tpj.ajax({
            url: '/Account/LoginAgency',
            type: 'post',
            dataType: 'json',
            data: { user: tpj.trim(tpj("#agency-user").val()), password: tpj.trim(tpj("#agency-password").val()) },
            success: function (data) {
                if (data.success) {

                    var reloadUrl = tpj.trim(tpj('#agency-reload-url').val());
                    if (reloadUrl != "") {
                        window.location = reloadUrl;
                    } else {
                        window.location = AGENCY_PANEL_PATH;
                    }

                } else {
                    alert(LOCALIZATIONS.LoginIncorrecto);
                }

            },
            error: function () {
                alert(LOCALIZATIONS.ConnectionError);
            }
        });
    });

    tpj("#affiliate-login-btn").click(function () {
        tpj.ajax({
            url: '/Account/LoginAffiliate',
            type: 'post',
            dataType: 'json',
            data: { user: tpj.trim(tpj("#affiliate-id").val()), password: tpj.trim(tpj("#affiliate-password").val()) },
            success: function (data) {
                if (data.success) {
                    window.location = AFFILIATE_PANEL_PATH;
                } else {
                    alert(LOCALIZATIONS.LoginIncorrecto);
                }

            },
            error: function () {
                alert(LOCALIZATIONS.ConnectionError);
            }
        });
    });

    tpj('.searchform').hover(function () {
        if (headerSearchFormTimerHandler) {
            clearTimeout(headerSearchFormTimerHandler);
        }

        tpj(this).find('#searchform').animate({
            display: 'block',
            opacity: '1',
            visibility: 'visible',
            width: '250px',
            paddingLeft: '25px'
        }, 200);
    }, function () {
        headerSearchFormTimerHandler = setTimeout(function () { hideHeaderSearchForm(tpj('.searchform')) }, 2000)
    });

    tpj('.searchform #searchform').focus(function () {
        if (headerSearchFormTimerHandler) {
            clearTimeout(headerSearchFormTimerHandler);
        }
        tpj(this).animate({
            display: 'block',
            opacity: '1',
            visibility: 'visible',
            width: '250px',
            paddingLeft: '25px'
        }, 200);
    });
    tpj(document).mouseup(function (e) {
        var container = tpj(".searchform");

        if (!container.is(e.target) // if the target of the click isn't the container...
            && container.has(e.target).length === 0) // ... nor a descendant of the container
        {
            if (headerSearchFormTimerHandler) {
                clearTimeout(headerSearchFormTimerHandler);
            }
            hideHeaderSearchForm(container)
        }
    });

   
});

function WlUupdateWhiteLabelIframeHeight() {
    var newheightBody = tpj("body").height();
    if (typeof parent !== 'undefined') parent.postMessage({ msgType: "newHeight", height: newheightBody }, '*');
}


function WlParentScrollToTop() {
    if (typeof parent !== 'undefined') parent.postMessage({ msgType: "scrollTo" }, '*');
}

function WlParentScrollTo(top) {
    parent.postMessage({ msgType: "scrollTo", top: top }, '*');
}

function hideHeaderSearchForm(container) {
    container.find('#searchform').animate({
        opacity: '0',
        visibility: 'hidden',
        width: '10px',
        paddingLeft: '0px'
    }, 200);
}

//CheckCookie();

var menu_scroll_interval_handler;

tpj(document).ready(function () {

    var belowBarLast = tpj('.below-bar > li').last();
    var belowBarFirst = tpj('.below-bar > li').first();
    var belowBarContainer = tpj('.below-bar').parents('.container').eq(0);

    tpj('.menu-more').hover(function () {
        if (belowBarLast.offset().left + belowBarLast.last().width() > belowBarContainer.offset().left + belowBarContainer.width()) {

            tpj(this).removeClass('animate-blink')

            menu_scroll_interval_handler = setInterval(function () {
                if (belowBarLast.offset().left + belowBarLast.last().width() > belowBarContainer.offset().left + belowBarContainer.width()) {
                    tpj('.below-bar > li').animate({
                        left: '-=20'
                    }, 115)
                } else {
                    clearInterval(menu_scroll_interval_handler);
                }
            }, 100)
        }
    }, function(){
        
        clearInterval(menu_scroll_interval_handler);
        
    })

    tpj('.menu-less').hover(function () {
        if (belowBarFirst.offset().left < belowBarContainer.offset().left) {
            menu_scroll_interval_handler = setInterval(function () {
                if (belowBarFirst.offset().left < belowBarContainer.offset().left) {
                    tpj('.below-bar > li').animate({
                        left: '+=20'
                    }, 115)
                } else {
                    clearInterval(menu_scroll_interval_handler);
                }
            }, 100)
        }
    }, function () {

        clearInterval(menu_scroll_interval_handler);

    })

});


tpj(document).ready(function () {

    echo.init({
        offset: 200,
        throttle: 250,
        unload: false
    });

    /*tpj("img").each(function () {
        tpj(this).attr("onerror", "this.src='/Hotel/Content/images/noimage.jpg'");
    })*/
    //On Error Images
    tpj(window).load(function () {

        tpj("img").each(function () {

            if (!this.complete || typeof this.naturalWidth == "undefined" || this.naturalWidth == 0) {

                //this.src = ASSETS_DOMAIN + "/Content/images/noimage.jpg";

            }

        });

        tpj(document).trigger("canariascom.noimage-error-finalized");

    });

    attachPopoverEvents();

    tpj('.mobileshare').click(function (e) {
        if (tpj('.social-sidebar').is(':visible')) {
            tpj('.social-sidebar').slideUp();
        } else {
            tpj('.social-sidebar').slideDown();
        }

    });


    tpj("#accordion, #accordion-agency, #accordion-affiliate").accordion({
        heightStyle: "content"
    });

    tpj(".buscadorheader-new").autocomplete({
        minLength: 2,
        source: function (request, response) {

            tpj.getJSON(Environment_APP_PATH + "core/search-hotel-with-url",
                        { take: 10, term: request.term },
                        function (data, status, xhr) {
                            var mappedData = tpj.map(data, function (item) {
                                return {
                                    label: item.name,
                                    value: item.url,
                                    type: "Hoteles"
                                };
                            });

                            response(mappedData);
                        });
        },
        select: function (event, ui) {

            window.location.href = Environment_APP_PATH + "hotel/" + ui.item.value;

            return false;
        }
    });

    tpj(".buscadorheader-submit").click(function () {
        var $buscador = tpj(this).siblings('.buscadorheader-new').first()
        if (tpj.trim($buscador.val()) != "") {
            window.location.href = Environment_APP_PATH + "Core/HotelSearch?term=" + tpj.trim($buscador.val());
        }
    });

    tpj(".buscadorheader-new").keyup(function (event) {
        if (event.keyCode == 13) {
            tpj(".buscadorheader-submit").click();
        }
    });




    tpj(window).on('load resize', function () {
        var width = tpj(window).width();
        var marginAdd = 115;
        var logoWidth = tpj('.botonera .navbar-header').outerWidth();
        var menuLeftWidth = tpj('.botonera #canmenu').outerWidth();
        var menuRightWidth = tpj('.botonera .navbar-right').outerWidth();
        var containerWidth = tpj('.botonera .container').outerWidth();
        //console.log("Container: " + containerWidth + " - Menu: " + (logoWidth + menuLeftWidth + menuRightWidth + marginAdd));
        
        if (containerWidth < (logoWidth + menuLeftWidth + menuRightWidth + marginAdd)) {
            if (tpj(".more-nav-sub").length == 0) {
                var moremenu = "<ul id='more-nav' class='nav navbar-nav'><li class='nivel1'><a data-toggle='dropdown' class='dropdown-toggle' href='#'>" + LOCALIZATIONS.Mas + "<span class='caret'></span></a><ul class='more-nav-sub dropdown-menu'></ul></li></ul>";
                tpj("#canmenu").after(moremenu);
                var totalItems = tpj("#canmenu > li").length;
                for (var i = totalItems - 1; i >= 0; i--) {
                    tpj(".more-nav-sub").prepend(tpj("#canmenu > li:gt(" + i + ")"));
                    menuLeftWidth = tpj('.botonera #canmenu').outerWidth();
                    //console.log("Arriba-> Container: " + containerWidth + " - Logo: " + logoWidth + " - Menu Left: " + menuLeftWidth + " - Menu Right: " + menuRightWidth + " Margin Add: " + marginAdd + " - Total: " + (logoWidth + menuLeftWidth + menuRightWidth + marginAdd));
                    if (containerWidth >= (logoWidth + menuLeftWidth + menuRightWidth + marginAdd)) {
                        break;
                    }
                }

            }
        } else {
            if (tpj(".more-nav-sub").length > 0) {
                var totalItems = tpj(".more-nav-sub li").length;
                for (var i = 1; i <= totalItems; i++) {
                    tpj(".more-nav-sub li:lt(" + i + ")").appendTo(tpj("#canmenu"));
                    
                    menuLeftWidth = tpj('.botonera #canmenu').outerWidth();
                    //console.log("Abajo-> Container: " + containerWidth + " - Logo: " + logoWidth + " - Menu Left: " + menuLeftWidth + " - Menu Right: " + menuRightWidth + " Margin Add: " + marginAdd + " - Total: " + (logoWidth + menuLeftWidth + menuRightWidth + marginAdd));
                    if (containerWidth <= (logoWidth + menuLeftWidth + menuRightWidth + marginAdd)) {
                        break;
                    }
                }
                if (tpj(".more-nav-sub li").length == 0) {
                    tpj('#more-nav').remove();
                }
            }
        }
    });



    tpj(document).ready(function () {

        if (tpj(window).width() >= 769) {
            tpj('.header-myaccount .dropdown-toggle').on('click', function (e) {
                e.stopPropagation();
                e.preventDefault();

                var self = tpj(this);
                if (tpj('.header-lang ul li').hasClass('open')) {
                    tpj('.header-lang ul li').removeClass('open');
                    tpj('.header-lang').removeClass("clicked");
                    tpj('.below-bar').toggleClass("nav-open");
                }
                if (tpj('.hotels.ul-base').hasClass('open')) {
                    tpj('.hotels.ul-base').toggleClass("open");
                }
                self.parent().toggleClass("open");
                tpj('.header-myaccount').toggleClass("clicked");
                tpj('.below-bar').toggleClass("nav-open");
            });

            tpj('.header-lang .dropdown-toggle').on('click', function (e) {
                e.stopPropagation();
                e.preventDefault();

                var self = tpj(this);
                if (tpj('.header-myaccount ul li').hasClass('open')) {
                    tpj('.header-myaccount ul li').removeClass('open');
                    tpj('.header-myaccount').removeClass("clicked");
                    tpj('.below-bar').toggleClass("nav-open");
                }
                if (tpj('.hotels.ul-base').hasClass('open')) {
                    tpj('.hotels.ul-base').toggleClass("open");
                }
                self.parent().toggleClass("open");
                tpj('.header-lang').toggleClass("clicked");
                tpj('.below-bar').toggleClass("nav-open");

            });

            tpj('.below-bar .hotels').on('click', function (e) {
                e.stopPropagation();
                e.preventDefault();
                if (tpj('.header-lang ul li').hasClass('open')) {
                    tpj('.header-lang ul li').removeClass('open');
                    tpj('.header-lang').removeClass("clicked");
                    tpj('.below-bar').toggleClass("nav-open");
                }
                if (tpj('.header-myaccount ul li').hasClass('open')) {
                    tpj('.header-myaccount ul li').removeClass('open');
                    tpj('.header-myaccount').removeClass("clicked");
                    tpj('.below-bar').toggleClass("nav-open");
                }
                tpj('.hotels.ul-base').toggleClass("open");
            });

            tpj(document).on('click', function (e) {
                if (tpj('.dropdown').hasClass('open')) {
                    tpj('.dropdown').removeClass('open');
                    tpj('.header-myaccount').removeClass("clicked");
                    tpj('.header-lang').removeClass("clicked");
                    tpj('.below-bar').removeClass("nav-open");
                }
                if (tpj('.hotels.ul-base').hasClass('open')) {
                    tpj('.hotels.ul-base').toggleClass("open");
                }
            });
            var tpjheader = tpj('header.main-navigation'),
                scrollClass = 'on-scroll',
                activateAtY = 95;

            function deactivateHeader() {
                if (!tpjheader.hasClass('scrolled')) {
                    tpjheader.addClass('scrolled');
                    tpj('.below-bar,.menu-more,.menu-less').hide(200);
                    tpj('.hotels.ul-base').removeClass('open')
                    tpj('.nav-btn').show(200);
                }
            }

            function activateHeader() {
                if (tpjheader.hasClass('scrolled')) {
                    tpjheader.removeClass('scrolled');
                    tpj('.below-bar,.menu-more,.menu-less').show(200);
                    tpj('.nav-btn').hide(200);
                }
            }

            tpj(window).scroll(function () {
                if (tpj(window).scrollTop() > activateAtY) {
                    deactivateHeader();
                } else {
                    activateHeader();
                }
            });

            tpj('.nav-btn').on('click', function (e) {
                if (tpj('header.main-navigation').hasClass('scrolled')) {
                    tpj('header.main-navigation').removeClass("scrolled");
                    tpj('.below-bar,.menu-more,.menu-less').show(200);
                } else {
                    tpj('.below-bar,.menu-more,.menu-less').hide(200);
                    tpj('header.main-navigation').addClass("scrolled");
                }
            });
        }
        if (tpj(window).width() <= 768) {
            tpj('.cd-dropdown-trigger').on('click', function (event) {
                event.preventDefault();
                toggleNav();
            });

            tpj('.cd-dropdown .cd-close').on('click', function (event) {
                event.preventDefault();
                toggleNav();
            });

            tpj('.has-children').children('a').on('click', function (event) {
                event.preventDefault();
                var selected = tpj(this);
                selected.next('ul').removeClass('is-hidden').end().parent('.has-children').parent('ul').addClass('move-out');
            });

            tpj('.go-back').on('click', function (event) {
                var selected = tpj(this),
                    visibleNav = tpj(this).parent('ul').parent('.has-children').parent('ul');
                selected.parent('ul').addClass('is-hidden').parent('.has-children').parent('ul').removeClass('move-out');
            });

            function toggleNav() {
                var navIsVisible = (!tpj('.cd-dropdown').hasClass('dropdown-is-active')) ? true : false;
                tpj('.cd-dropdown').toggleClass('dropdown-is-active', navIsVisible);
                tpj('.cd-dropdown-trigger').toggleClass('dropdown-is-active', navIsVisible);
                if (!navIsVisible) {
                    tpj('.cd-dropdown').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
                        tpj('.has-children ul').addClass('is-hidden');
                        tpj('.move-out').removeClass('move-out');
                        tpj('.is-active').removeClass('is-active');
                    });
                }
            };
        }

        tpj("#home-slider").owlCarousel({
            autoPlay: 6000,
            navigation: true,
            navigationText: [
            "<i class='fa fa-chevron-left icon-white'></i>",
            "<i class='fa fa-chevron-right icon-white'></i>"
            ],
            items: 1,
        });



        tpj("#lastminuteoffers").owlCarousel({
            autoPlay: 6000,
            navigation: true,
            navigationText: [
            "<i class='fa fa-chevron-left icon-white'></i>",
            "<i class='fa fa-chevron-right icon-white'></i>"
            ],
            items: tpj("#lastminuteoffers").data('itemsperpage'),
            itemsDesktop: [1500, tpj("#lastminuteoffers").data('itemsperpage')],
            itemsDesktopSmall: [979, 2],
            itemsTablet: [670, 1]
        });
        var carouselCount = 0;
        tpj(".lastminuteoffer").each(function () {
            tpj(this).attr("id", "lastminuteoffer" + carouselCount);
            tpj('#lastminuteoffer' + carouselCount).owlCarousel({
                items: 1,
                itemsDesktop: [1199, 1],
                itemsDesktopSmall: [980, 1],
                itemsTablet: [768, 1],
                itemsTabletSmall: false,
                itemsMobile: [479, 1],
                rewindNav: false,
                navigation: true,
                navigationText: [
                "<i class='fa fa-chevron-left icon-white'></i>",
                "<i class='fa fa-chevron-right icon-white'></i>"
                ],
                responsive: true,
                responsiveRefreshRate: 200,
                responsiveBaseWidth: window,
                pagination: false
            });
            carouselCount++;
        });



        tpj('#helpsplash, .floatinghelp').click(function (e) {
            tpj('.noxreservacontent, .preciominimocontent').hide();
            tpj('.helpsplash').show();
        });

        tpj('#offersplash').click(function (e) {
            tpj('.offersplash').show();
        });

        tpj('#waitsplash').click(function (e) {
            tpj('.waitsplash').show();
        });

        tpj('#socialsplash').click(function (e) {
            tpj('.socialsplash').show();
            tpj('.scont img').css({ 'transform': 'scale3d(1,1,1)' });
        });

        tpj('#preciominimo').click(function (e) {
            tpj('.spcontent').hide();
            tpj('.loading2').css({ 'width': '75%' });
            tpj('.preciominimocontent').show();
        });

        tpj('.preciominimocontent-close').click(function () {
            tpj('.spcontent').show();
            tpj('.loading2').css({ 'width': 'auto' });
            tpj('.preciominimocontent').hide();
        });

        tpj('#nocobramosxreserva').click(function (e) {
            tpj('.noxreservacontent').show();
        });

        var _exitsplash = exitsplash(document.getElementById('exitsplash'), {
            aggressive: false,
            timer: 0,
            callback: function () {
                tpj('.offersplash').show();
                tpj('#overlay2, #overlay4, #overlay5').hide();
            }
        });

        tpj('body').click(function (e) {
            tpj('#exitsplash').hide();
        });
        tpj('.helpsplash .splashcta').click(function (e) {
            tpj('.spcontent').show();
            tpj('.loading2').css({ 'width': 'auto' });
            tpj('.helpsplash').hide();
        });



    });

    tpj('.cookie-lang-switcher').click(function (e) {

        e.preventDefault();
        e.stopPropagation();

        var langCode = tpj(this).attr('data-lang');

        if (typeof langCode !== typeof undefined && langCode !== false) {
            setLanguageCookie(langCode);
        }
        
        gaSendEventAndRedirect('CanariasFull.send', 'event', 'langclick', 'langchange', tpj(this).data('lang'), tpj(this).attr('href'));
        

    })

});

function setLanguageCookie(langCode) {
    tpj.cookie(COOKIE_LANG, langCode, { expires: 365, path: '/' })    
}

function attachPopoverEvents() {
    var placement = 'top';


    var placement = tpj(window).width() <= 480 ? 'top' : 'left';
    tpj('.cTooltip').popover({
        html: true,
        content: function () {
            var target = tpj(this).parents('.row').eq(0).find(tpj(this).data('tooltip-target'));
            if (target.length > 0) {
                //Set some text
                var facs = target.find('.facilities');
                if (facs.text().length > 0 && facs.find('.facilities-title').length == 0) {
                    facs.prepend('<span class="facilities-title">' + LOCALIZATIONS.Equipamiento + '</span>');
                }

                //Set icons
                var can = target.find('.POLICY_CANCELLATION');
                if (can.text().length > 0 && can.find('i').length == 0) {
                    can.prepend('<i class="fa fa-ban"></i> ').addClass("text-danger");
                }
                var prepay = target.find('.POLICY_PREPAY');
                if (prepay.text().length > 0 && prepay.find('i').length == 0) {
                    prepay.prepend('<i class="fa fa-money"></i> ');
                }
                var meal = target.find('.POLICY_HOTEL_MEALPLAN');
                if (meal.text().length > 0 && meal.find('i').length == 0) {
                    meal.prepend('<i class="fa fa-cutlery"></i> ');
                }
                var policy = target.find('.POLICY_SUMMARY_INCLUDED');
                if (policy.text().length > 0 && policy.find('i').length == 0) {
                    policy.prepend('<i class="fa fa-percent"></i> ');
                }

                policy = target.find('.POLICY_SUMMARY_EXCLUDED');
                if (policy.text().length > 0 && policy.find('i').length == 0) {
                    policy.prepend('<i class="fa fa-percent"></i> ');
                }
                var pets = target.find('.POLICY_HOTEL_PETS');
                if (pets.text().length > 0 && pets.find('i').length == 0) {
                    pets.prepend('<i class="fa fa-paw"></i> ');
                }
                var extracharge = target.find('.POLICY_HOTEL_EXTRACHARGES');
                if (extracharge.text().length > 0 && extracharge.find('i').length == 0) {
                    extracharge.prepend('<i class="fa fa-percent"></i> ');
                }
                var children = target.find('.POLICY_CHILDREN');
                if (children.text().length > 0 && children.find('i').length == 0) {
                    children.prepend('<i class="fa fa-child"></i> ');
                }
                var internet = target.find('.POLICY_HOTEL_INTERNET');
                if (internet.text().length > 0 && internet.find('i').length == 0) {
                    internet.prepend('<i class="fa fa-wifi"></i> ');
                }
                var parking = target.find('.POLICY_HOTEL_PARKING');
                if (parking.text().length > 0 && parking.find('i').length == 0) {
                    parking.prepend('<i class="fa fa-car"></i> ');
                }
                var preauth = target.find('.POLICY_PREAUTHORIZE');
                if (preauth.text().length > 0 && preauth.find('i').length == 0) {
                    preauth.prepend('<i class="fa fa-credit-card"></i> ');
                }
                target.find('.important_information').addClass('alert alert-info');

                var html = target.html();
                //target.remove();
                return html.replace(/data-id/g, "id");
            } else {
                return "";
            }
        },
        title: function () {
            return tpj(this).prop('title');
        },
        trigger: 'hover',
        container: 'body',
        trigger: 'manual',
        placement: placement,
        animation: false
    }).on("mouseenter", function () {
        var _this = this;
        tpj(this).popover("show");
        tpj(".popover").on("mouseleave", function () {
            tpj(_this).popover('hide');
        });
    }).on("mouseleave", function () {
        var _this = this;
        setTimeout(function () {
            if (!tpj(".popover:hover").length) {
                tpj(_this).popover("hide");
            }
        }, 300);
    })

    
    

    tpj('.vTooltip,[data-toggle="tooltip"]').tooltip({
        placement: 'bottom',
        container: 'body'
    });

}

// Called when a link to a product is clicked.
function gaOnProductClick(evt, obj, redirectUrl) {
    evt.stopPropagation();
    evt.preventDefault();

    if (window.ga && ga.loaded) {

        var hotelWrap = tpj(obj).parents('.offer-item').eq(0);
        if (hotelWrap.length > 0) {
            ga('CanariasFull.ec:addProduct', {
                'id': hotelWrap.data('hotelid'),
                'name': hotelWrap.data('hotelname'),
                'category': hotelWrap.data('hotelcategory'),
                //'brand': 'Google',
                //'variant': 'black',
                'position': (hotelWrap.parent().index(hotelWrap) + 1)
            });
            var listName = hotelWrap.parents('.hotellistwrap').eq(0).data('listname');
            ga('CanariasFull.ec:setAction', 'click', { list: listName });

            saveInLocalStorage('last_hotel_list', listName);

            // Send click with an event, then send user to product page.
            ga('CanariasFull.send', 'event', 'UX', 'click', 'Results', {
                hitCallback: function () {
                    document.location = redirectUrl;
                }
            });
        }
    } else {
        document.location = redirectUrl;
    }
}

function gaSendEventAndRedirect(p1, p2, p3, p4, p5, redirectUrl) {
    if (window.ga && ga.loaded) {
        ga(p1, p2, p3, p4, p5, {
            hitCallback: function () {
                document.location = redirectUrl;
            }
        });
    } else {
        document.location = redirectUrl;
    }
}


function gaAddToCart(products) {
    if (window.ga && ga.loaded) {
        var canariasGAShoppingCart = getFromLocalStorage("gaCart", []);
        //Clear cart
        if (canariasGAShoppingCart.length > 0) {
            for (var key in canariasGAShoppingCart) {
                var item = canariasGAShoppingCart[key];

                ga('CanariasFull.ec:addProduct', {
                    'id': item.id,
                    'name': item.name,
                    'category': item.category,
                    //'brand': product.brand,
                    //'variant': product.variant,
                    'price': item.price / item.qty,
                    'quantity': item.qty
                });

            }
            ga('CanariasFull.ec:setAction', 'remove', { id: tpj('body').data('sid'), list: getFromLocalStorage('last_hotel_list', '') });
            ga('CanariasFull.send', 'event', 'UX', 'click', 'remove from cart');
        }

        canariasGAShoppingCart = [];

        //Add to internal cart
        for (var key in products) {
            var product = products[key];
            canariasGAShoppingCart.push(product);

            ga('CanariasFull.ec:addProduct', {
                'id': product.id,
                'name': product.name,
                'category': product.category,
                //'brand': product.brand,
                //'variant': product.variant,
                'price': product.price / product.qty,
                'quantity': product.qty
            });
        }

        saveInLocalStorage('gaCart', canariasGAShoppingCart);

        //Add to GA Cart
        
        ga('CanariasFull.ec:setAction', 'add', { id: tpj('body').data('sid'), list: getFromLocalStorage('last_hotel_list', '') });
        ga('CanariasFull.send', 'event', 'UX', 'click', 'add to cart');     // Send data using an event.

    }
}

function gaCheckout(paymentMethod, coupon, callback) {
    if (window.ga && ga.loaded) {
        var canariasGAShoppingCart = getFromLocalStorage("gaCart", []);
        
        if (canariasGAShoppingCart.length > 0) {

            //Add to internal cart
            for (var key in canariasGAShoppingCart) {
                var product = canariasGAShoppingCart[key];
                

                ga('CanariasFull.ec:addProduct', {
                    'id': product.id,
                    'name': product.name,
                    'category': product.category,
                    //'brand': product.brand,
                    //'variant': product.variant,
                    'price': product.price / product.qty,
                    'quantity': product.qty
                });
            }

            

            // In the case of checkout actions, an additional actionFieldObject can
            // specify a checkout step and option.
            var actionFieldObject = {
                'id': tpj('body').data('sid'),
                'step': 1,            // A value of 1 indicates this action is first checkout step.
                'option': paymentMethod,      // Used to specify additional info about a checkout stage, e.g. payment method.
                'list': getFromLocalStorage('last_hotel_list', '')
            };
            if (coupon && coupon !== '') {
                actionFieldObject.coupon = coupon;
            }
            ga('CanariasFull.ec:setAction', 'checkout', actionFieldObject);

            
            ga('CanariasFull.send', 'event', 'Checkout', 'Option', {
                hitCallback: function () {
                    callback();
                }
            });
        } else {
            callback();
        } 
    } else {
        callback();
    } 


}
		jQuery(document).ready(function($){
			function onBgresize() {
				var $gfwidth = window.innerWidth;
				var $gfheight = window.innerHeight;
				
				var $loginw = jQuery('.login-wrap2').width();
				var $loginh = jQuery('.login-wrap2').height();
				
				jQuery('.login-fullwidith').css({ 'width': $gfwidth + 'px', 'height': $gfheight + 'px' });
				
				jQuery('.login-wrap2').css({ 'margin-left': $gfwidth / 2 - $loginw / 2 + 'px', 'margin-top': $gfheight / 2 - $loginh / 2 + 'px' });
				
			}
			onBgresize();
			jQuery(window).resize(function () {
				onBgresize();
			});
		});		
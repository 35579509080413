



jQuery(document).ready(function(){
"use strict";
/* GETS 100% HEIGHT FOR FILTERS BG*/
//var jQuerypgc = jQuery('.pagecontainer').height();
//jQuery('.filters').css({'height':jQuerypgc+'px'});



});

// ########################
// BACK TO TOP FUNCTION
// ########################


jQuery(document).ready(function(){
"use strict";
	
	// hide #back-top first
	jQuery("#back-top").hide();
	
	// fade in #back-top
	jQuery(function () {
		jQuery(window).scroll(function () {
			if (jQuery(this).scrollTop() > 700) {
				jQuery('#back-top').fadeIn();
			} else {
				jQuery('#back-top').fadeOut();
			}
		});

		// scroll body to 0px on click
		jQuery('#back-top a').click(function () {
			jQuery('body,html').animate({
				scrollTop: 0
			}, 500);
			return false;
		});
		
				// scroll body to 0px on click
		jQuery('a#gotop2').click(function () {
			jQuery('body,html').animate({
				scrollTop: 0
			}, 500);
			return false;
		});
		
		var jQueryih = jQuery('body').innerHeight();
		
		jQuery(".scroll").click(function(event){		
			event.preventDefault();
			jQuery('html,body').animate({scrollTop:jQuery(this.hash).offset().top - jQueryih}, 1500);
		});
		
		
		
		
		
	});
});



function errorMessage() {
    tpj('.login-wrap').animo({ animation: 'tada' });
}

function UpdateWhiteLabelIframeHeight() {
    
    var newheightBody = tpj("body").height();
    var newHeightBox = -1;
    if (tpj('.searchonslider').length > 0) {
        newHeightBox = tpj('.searchonslider').height();
    }
    
    var newHeight = Math.max(newheightBody, newHeightBox) + "px";
    
    parent.postMessage({ msgType: "newHeight", height: newHeight }, '*');
}